import React, { useEffect, useState } from "react";
import {
 Box,
 Heading,
 Text,
 VStack,
 HStack,
 Button,
 Divider,
 RadioGroup,
 Radio,
 Icon,
 useDisclosure,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalBody,
 ModalFooter,
 Stack,
 Input,
 Avatar,
} from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select"; // Import react-select
import { fetchUserAddresses } from "../../redux/cartSlice";
import { Badge } from "reactstrap";

const AddressForm = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const { user } = useSelector((state) => state.auth);
 const userId = user?._id;
 const { addresses = [] } = user || {};
 const { savedAddresses, loading, error } = useSelector((state) => state.cart);

 // State for address form
 const [newAddress, setNewAddress] = useState({
  name: "",
  city: "",
  houseFlatNumber: "",
  completeAddress: "",
  pincode: "",
  landmark: "",
 });

 const [selectedAddressId, setSelectedAddressId] = useState("");
 const [serviceableData, setServiceableData] = useState([]);
 const [cityOptions, setCityOptions] = useState([]); // Cities for dropdown
 const [pincodeOptions, setPincodeOptions] = useState([]); // Pincodes for dropdown
 const [isLoadingCities, setIsLoadingCities] = useState(true);

 const { isOpen, onOpen, onClose } = useDisclosure();
 const customStyles = {
  control: (base) => ({
   ...base,
   border: "2px solid #cccc",
   borderRadius: "8px",
   boxShadow: "none",
   "&:hover": { borderColor: "darkred" },
   width: "100%", // Full width
   padding: "5px",
  }),
  option: (base, { isFocused }) => ({
   ...base,
   backgroundColor: isFocused ? "rgba(255, 0, 0, 0.1)" : "white",
   color: "black",
   display: "flex",
   alignItems: "center",
   padding: "10px",
  }),
  menu: (base) => ({
   ...base,
   width: "100%",
  }),
 };

 // Fetch user addresses on mount
 useEffect(() => {
  if (userId) {
   dispatch(fetchUserAddresses(userId));
  }
 }, [userId, dispatch]);

 // Fetch cities and pincodes
 useEffect(() => {
  const fetchServiceableCities = async () => {
   try {
    const response = await axios.get(
     "https://app-api.carexpert.org.in/api/cities"
    );

    setServiceableData(response.data);
    setCityOptions(
     response.data.map((city) => ({
      label: city.city, // Displayed text
      value: city.city, // Stored value
      pincodes: city.pincodes, // Pincodes related to this city
      image: city.image || "https://via.placeholder.com/30",
     }))
    );

    setIsLoadingCities(false);
   } catch (err) {
    console.error("Error fetching cities:", err);
    setIsLoadingCities(false);
   }
  };

  fetchServiceableCities();
 }, []);

 // Handle city selection
 const handleCityChange = (selectedCity) => {
  setNewAddress((prev) => ({
   ...prev,
   city: selectedCity.value,
   pincode: "", // Reset pincode when city changes
  }));

  // Find corresponding pincodes
  const selectedCityData = serviceableData.find(
   (c) => c.city === selectedCity.value
  );
  if (selectedCityData) {
   setPincodeOptions(
    selectedCityData.pincodes.map((pincode) => ({
     label: pincode,
     value: pincode,
    }))
   );
  } else {
   setPincodeOptions([]);
  }
 };

 // Handle pincode selection
 const handlePincodeChange = (selectedPincode) => {
  setNewAddress((prev) => ({
   ...prev,
   pincode: selectedPincode.value,
  }));
 };

 // Check if pincode is serviceable
 const isServiceable = (pincode) => {
  return serviceableData.some((city) => city.pincodes.includes(pincode));
 };

 // Handle "Continue" click
 //  const handleContinue = async () => {
 //   if (selectedAddressId) {
 //    const selectedAddress = savedAddresses.find(
 //     (addr) => addr._id === selectedAddressId
 //    );

 //    if (!isServiceable(selectedAddress.pincode)) {
 //     onOpen();
 //    } else {
 //     navigate("/cart-summary", {
 //      state: { address: selectedAddress, type: "EXISTING" },
 //     });
 //    }
 //   } else {
 //    if (!isServiceable(newAddress.pincode)) {
 //     onOpen();
 //    } else {
 //     navigate("/cart-summary", { state: { address: newAddress, type: "NEW" } });
 //    }
 //   }
 //  };
 const formatOptionLabel = ({ label, image }) => (
  <Box w="100%" style={{ display: "flex", alignItems: "center" }}>
   <Avatar
    width={"500px"}
    src={image}
    alt={label}
    style={{ width: "20px", height: "20px", marginRight: "10px" }}
   />
   {label}
  </Box>
 );
 const pincodeSelectStyles = {
  control: (base, { isDisabled }) => ({
   ...base,
   border: isDisabled ? "2px solid gray" : "2px solid #ccc",
   borderRadius: "8px",
   boxShadow: "none",
   "&:hover": { borderColor: isDisabled ? "gray" : "darkred" },
   width: "100%", // Full width
   padding: "5px",
   backgroundColor: isDisabled ? "#f5f5f5" : "white",
  }),
  option: (base, { isFocused }) => ({
   ...base,
   backgroundColor: isFocused ? "rgba(255, 0, 0, 0.1)" : "white",
   color: "black",
   padding: "10px",
  }),
  menu: (base) => ({
   ...base,
   width: "100%",
  }),
 };
 const customInputStyles = {
  //   border: "2px solid #ccc", // Red border
  borderRadius: "8px",
  padding: "10px",
  _focus: {
   borderColor: "#C53030",
   boxShadow: "0 0 5px rgba(229, 62, 62, 0.5)",
  }, // Focus effect
  _hover: { borderColor: "#C53030" }, // Hover effect
  backgroundColor: "white",
 };
 console.log("newAddress:", newAddress);
 const handleContinue = async () => {
  if (selectedAddressId) {
   // Case: User selects a saved address
   const selectedAddress = savedAddresses.find(
    (addr) => addr._id === selectedAddressId
   );
   console.log("selectedAddress:", selectedAddress);
   if (!isServiceable(selectedAddress.pincode)) {
    onOpen(); // Show modal if not serviceable
   } else {
    navigate("/cart-summary", {
     state: { address: selectedAddress, type: "EXISTING" },
    });
   }
  } else {
   // Case: User enters a new address or updates an existing one
   try {
    let response;

    if (newAddress._id) {
     // Updating existing address
     response = await axios.patch(
      `https://app-api.carexpert.org.in/api/user/${userId}/update-address`,
      {
       ...newAddress,
       addressId: newAddress._id,
      }
     );
    }
    navigate("/cart-summary", {
     state: {
      address: response.data.address,
      type: newAddress._id ? "UPDATED" : "NEW",
     },
    });
    // if (response.data.success) {
    //  dispatch(fetchUserAddresses(userId)); // Refresh address list
    // }
   } catch (error) {
    console.error("Error saving address:", error);
   }
  }
 };

 // Handle Edit Address
 const handleEditAddress = (address) => {
  setNewAddress(address); // Prefill form with selected address
  setSelectedAddressId(""); // Ensure form mode is active
 };
 console.log("addresses:", addresses);
 return (
  <Box
   w={{ base: "100%", md: "100%", lg: "60%" }}
   mx="auto"
   mt={8}
   mb={10}
   p={6}
   bg="white"
  >
   <Heading as="h2" size="lg" mb={4}>
    Select / Add Address
   </Heading>
   <Text fontSize="sm" color="gray.600" mb={6}>
    Select from saved addresses or add a new address
   </Text>

   {loading && <Text>Loading saved addresses...</Text>}
   {error && <Text color="red.500">Error: {error}</Text>}
   {isLoadingCities && <Text>Loading serviceable cities...</Text>}

   {/* New Address Form */}
   <Box display={"flex"} flexDirection={"column"} gap={"5"}>
    <Input
     placeholder="Full Name"
     name="name"
     value={newAddress.name}
     onChange={(e) => setNewAddress({ ...newAddress, name: e.target.value })}
     sx={customInputStyles}
    />
    {/* City Dropdown */}
    <Select
     styles={customStyles}
     options={cityOptions}
     onChange={handleCityChange}
     value={cityOptions.find((option) => option.value === newAddress.city)}
     placeholder="Select City"
     isSearchable
     formatOptionLabel={formatOptionLabel}
    />

    {/* Pincode Dropdown */}
    {/* <Select
     options={pincodeOptions}
     onChange={handlePincodeChange}
     value={pincodeOptions.find(
      (option) => option.value === newAddress.pincode
     )}
     placeholder="Select Pincode"
     isSearchable
     isDisabled={!newAddress.city} // Disable until city is selected
    /> */}
    <Select
     styles={pincodeSelectStyles}
     options={pincodeOptions}
     onChange={handlePincodeChange}
     value={pincodeOptions.find(
      (option) => option.value === newAddress.pincode
     )}
     placeholder="Select Pincode"
     isSearchable
     isDisabled={!newAddress.city} // Disable until a city is selected
    />

    <Input
     placeholder="House/Flat Number"
     name="houseFlatNumber"
     value={newAddress.houseFlatNumber}
     onChange={(e) =>
      setNewAddress({ ...newAddress, houseFlatNumber: e.target.value })
     }
     sx={customInputStyles}
    />

    <Input
     placeholder="Landmark"
     name="landmark"
     value={newAddress.landmark}
     onChange={(e) =>
      setNewAddress({ ...newAddress, landmark: e.target.value })
     }
    />
   </Box>

   <Divider mb={6} />

   {/* Saved Addresses */}
   <Heading as="h3" size="md" mb={4}>
    Choose from Saved Addresses
   </Heading>

   <RadioGroup value={selectedAddressId} onChange={setSelectedAddressId}>
    <VStack spacing={4} align="stretch">
     {addresses.length > 0 ? (
      addresses.map((addr) => (
       <Box key={addr._id} p={4} borderWidth={1} borderRadius="md">
        <HStack justify="space-between">
         <HStack>
          <Icon as={FiMapPin} color="blue.500" boxSize={6} />
          <VStack align="start" spacing={1}>
           <Text fontWeight="bold">{addr.name}</Text>
           <Text fontSize="sm">
            {addr.houseFlatNumber}, {addr.completeAddress}
           </Text>
           <Text fontSize="sm">{addr.landmark}</Text>
           <Text fontSize="sm">
            {addr.city} - {addr.pincode}
           </Text>
           {addr.currentAddress && (
            <Badge colorScheme="green" fontSize="0.8em">
             Current Address
            </Badge>
           )}
          </VStack>
         </HStack>
         <Radio value={addr._id} />
        </HStack>
       </Box>
      ))
     ) : (
      <Text color="gray.500">No saved addresses found.</Text>
     )}
    </VStack>
   </RadioGroup>

   <Button colorScheme="red" w="100%" mt={6} onClick={handleContinue}>
    Continue →
   </Button>

   {/* <Heading as="h3" size="md" mb={4}>
    Choose from Saved Addresses
   </Heading>

   <RadioGroup value={selectedAddressId} onChange={setSelectedAddressId}>
    <VStack spacing={4} align="stretch">
     {savedAddresses?.map((addr) => (
      <Box key={addr._id} p={4} borderWidth={1} borderRadius="md">
       <HStack justify="space-between">
        <HStack>
         <Icon as={FiMapPin} color="blue.500" boxSize={6} />
         <Text fontSize="sm">
          {addr.name}, {addr.pincode}
         </Text>
        </HStack>
        <Radio value={addr._id} />
       </HStack>
      </Box>
     ))}
    </VStack>
   </RadioGroup>

   <Button colorScheme="red" w="100%" mt={6} onClick={handleContinue}>
    Continue →
   </Button> */}
  </Box>
 );
};

export default AddressForm;

// import React, { useEffect, useState } from "react";
// import {
//  Box,
//  Heading,
//  Text,
//  Input,
//  VStack,
//  HStack,
//  Button,
//  Divider,
//  RadioGroup,
//  Radio,
//  Icon,
//  useDisclosure,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalBody,
//  ModalFooter,
//  Stack,
// } from "@chakra-ui/react";
// import { FiMapPin } from "react-icons/fi";
// import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { fetchUserAddresses } from "../../redux/cartSlice";

// const AddressForm = () => {
//  const dispatch = useDispatch();
//  const navigate = useNavigate();

//  // Example: user is stored in auth slice
//  const { user } = useSelector((state) => state.auth);
//  const userId = user?._id; // e.g. "66e723460a6928913c986363"

//  // Pull savedAddresses, loading, error from Redux cart slice
//  const { savedAddresses, loading, error } = useSelector((state) => state.cart);

//  // Local state
//  const [newAddress, setNewAddress] = useState({
//   name: "",
//   city: "",
//   houseFlatNumber: "",
//   completeAddress: "",
//   pincode: "",
//   landmark: "",
//  });
//  const [selectedAddressId, setSelectedAddressId] = useState("");
//  const [serviceableData, setServiceableData] = useState([]);
//  const [isLoadingCities, setIsLoadingCities] = useState(true);

//  // Modal for serviceability
//  const { isOpen, onOpen, onClose } = useDisclosure();

//  // Fetch user addresses on mount (or userId change)
//  useEffect(() => {
//   if (userId) {
//    dispatch(fetchUserAddresses(userId));
//   }
//  }, [userId, dispatch]);

//  // Fetch serviceable cities and pincodes on mount
//  useEffect(() => {
//   const fetchServiceableCities = async () => {
//    try {
//     const response = await axios.get(
//      "https://app-api.carexpert.org.in/api/cities"
//     );
//     setServiceableData(response.data);
//     setIsLoadingCities(false);
//    } catch (err) {
//     console.error("Error fetching serviceable cities:", err);
//     setIsLoadingCities(false);
//    }
//   };

//   fetchServiceableCities();
//  }, []);

//  // Handle address input change
//  const handleAddressChange = (e) => {
//   const { name, value } = e.target;
//   setNewAddress((prev) => ({
//    ...prev,
//    [name]: value,
//   }));
//  };

//  // Check if pincode is serviceable
//  const isServiceable = (pincode) => {
//   return serviceableData.some((city) => city.pincodes.includes(pincode));
//  };

//  // Handle "Continue" click
//  const handleContinue = async () => {
//   if (selectedAddressId) {
//    // User selected an existing address
//    const selectedAddress = savedAddresses.find(
//     (addr) => addr._id === selectedAddressId
//    );

//    // Validate if the selected address has a serviceable pincode
//    if (!isServiceable(selectedAddress.pincode)) {
//     onOpen(); // Open modal if pincode is not serviceable
//    } else {
//     // Proceed to the next page with the selected existing address
//     navigate("/cart-summary", {
//      state: { address: selectedAddress, type: "EXISTING" },
//     });
//    }
//   } else {
//    // User entered a new address
//    if (!isServiceable(newAddress.pincode)) {
//     onOpen(); // Open modal if pincode is not serviceable
//    } else {
//     // Proceed to the next page with the new address
//     navigate("/cart-summary", {
//      state: { address: newAddress, type: "NEW" },
//     });
//    }
//   }
//  };

//  return (
//   <Box
//    w={{
//     base: "100%",
//     md: "100%",
//     lg: "60%",
//    }}
//    mx="auto"
//    mt={8}
//    mb={10}
//    p={6}
//    bg="white"
//   >
//    <Heading as="h2" size="lg" mb={4}>
//     Select / Add Address
//    </Heading>
//    <Text fontSize="sm" color="gray.600" mb={6}>
//     Select from saved addresses or add a new address
//    </Text>

//    {/* Loading & Error Handling */}
//    {loading && <Text>Loading saved addresses...</Text>}
//    {error && <Text color="red.500">Error: {error}</Text>}
//    {isLoadingCities && <Text>Loading serviceable cities...</Text>}

//    {/* New Address Form */}
//    <VStack spacing={4} mb={6}>
//     <Input
//      placeholder="Full Name"
//      name="name"
//      value={newAddress.name}
//      onChange={handleAddressChange}
//     />
//     <Input
//      placeholder="House/Flat Number"
//      name="houseFlatNumber"
//      value={newAddress.houseFlatNumber}
//      onChange={handleAddressChange}
//     />
//     <Input
//      placeholder="Complete Address"
//      name="completeAddress"
//      value={newAddress.completeAddress}
//      onChange={handleAddressChange}
//     />
//     <Input
//      placeholder="Pincode"
//      name="pincode"
//      value={newAddress.pincode}
//      onChange={handleAddressChange}
//     />
//     <Input
//      placeholder="Landmark"
//      name="landmark"
//      value={newAddress.landmark}
//      onChange={handleAddressChange}
//     />
//    </VStack>

//    <Divider mb={6} />

//    {/* Saved Addresses */}
//    <Heading as="h3" size="md" mb={4}>
//     Choose from Saved Addresses
//    </Heading>

//    <RadioGroup
//     value={selectedAddressId}
//     onChange={(value) => setSelectedAddressId(value)}
//    >
//     <VStack spacing={4} align="stretch">
//      {savedAddresses?.map((addr) => (
//       <Box
//        key={addr._id}
//        p={4}
//        borderWidth={1}
//        borderRadius="md"
//        bg={selectedAddressId === addr._id ? "blue.50" : "gray.50"}
//        borderColor={selectedAddressId === addr._id ? "blue.500" : "gray.200"}
//       >
//        <HStack justify="space-between" align="center">
//         <HStack>
//          <Icon as={FiMapPin} color="blue.500" boxSize={6} />
//          <Text fontSize="sm" color="gray.700">
//           {addr.name}, {addr.houseFlatNumber}, {addr.completeAddress}, Pincode:{" "}
//           {addr.pincode}
//          </Text>
//         </HStack>
//         <Radio value={addr._id} />
//        </HStack>
//       </Box>
//      ))}
//     </VStack>
//    </RadioGroup>

//    {/* Footer Button */}
//    <Button colorScheme="red" w="100%" mt={6} onClick={handleContinue}>
//     Continue →
//    </Button>

//    {/* Modal for Non-Serviceable Pincode */}
//    <Modal isOpen={isOpen} onClose={onClose}>
//     <ModalOverlay />
//     <ModalContent>
//      <ModalHeader>Service Not Available</ModalHeader>
//      <ModalBody>
//       <Text>
//        Unfortunately, we do not provide services in the entered pincode. Please
//        check again or contact support.
//       </Text>
//      </ModalBody>
//      <ModalFooter>
//       <Button colorScheme="blue" onClick={onClose}>
//        OK
//       </Button>
//      </ModalFooter>
//     </ModalContent>
//    </Modal>
//   </Box>
//  );
// };

// export default AddressForm;

// // import React, { useEffect, useState } from "react";
// // import {
// //  Box,
// //  Heading,
// //  Text,
// //  Input,
// //  VStack,
// //  HStack,
// //  Button,
// //  Divider,
// //  RadioGroup,
// //  Radio,
// //  Icon,
// //  Stack,
// // } from "@chakra-ui/react";
// // import { FiMapPin } from "react-icons/fi";
// // import { useSelector, useDispatch } from "react-redux";
// // // import { fetchUserAddresses, createUserAddress } from "../../redux/cartSlice";
// // import { useLocation, useNavigate } from "react-router-dom";
// // import { fetchUserAddresses } from "../../redux/cartSlice";

// // const AddressForm = () => {
// //  const dispatch = useDispatch();
// //  const navigate = useNavigate();

// //  // Example: user is stored in auth slice
// //  const { user } = useSelector((state) => state.auth);
// //  const userId = user._id; // e.g. "66e723460a6928913c986363"

// //  // Pull savedAddresses, loading, error from Redux cart slice
// //  const { savedAddresses, loading, error } = useSelector((state) => state.cart);

// //  // Local state for new address input
// //  // Make sure to collect the full fields (matching your backend)
// //  const [newAddress, setNewAddress] = useState({
// //   name: "",
// //   houseFlatNumber: "",
// //   completeAddress: "",
// //   pincode: "",
// //   landmark: "",
// //  });

// //  // Selected existing address _id
// //  const [selectedAddressId, setSelectedAddressId] = useState("");

// //  // Fetch addresses on mount (or userId change)
// //  useEffect(() => {
// //   if (userId) {
// //    dispatch(fetchUserAddresses(userId));
// //   }
// //  }, [userId, dispatch]);

// //  // Handle typed input for new address
// //  const handleAddressChange = (e) => {
// //   const { name, value } = e.target;
// //   setNewAddress((prev) => ({
// //    ...prev,
// //    [name]: value,
// //   }));
// //  };

// //  // On “Continue” click
// //  const handleContinue = async () => {
// //   if (selectedAddressId) {
// //    // The user selected an existing address
// //    const addressToUse = {
// //     addressId: selectedAddressId,
// //     type: "EXISTING",
// //    };
// //    // Navigate to /cart-summary, passing the address choice
// //    navigate("/cart-summary", {
// //     state: { address: addressToUse || newAddress },
// //    });
// //   } else {
// //    // The user typed a new address
// //    try {
// //     const addressToUse = {
// //      addressId: selectedAddressId,
// //      type: "EXISTING",
// //     };
// //     navigate("/cart-summary", {
// //      state: { address: newAddress },
// //     });
// //     // 1) Dispatch the createUserAddress thunk
// //     // const resultAction = await dispatch();
// //     //  createUserAddress({ userId, newAddress })
// //     // if (createUserAddress.fulfilled.match(resultAction)) {
// //     //  // The newly created address is in resultAction.payload
// //     //  const createdAddress = resultAction.payload;
// //     //  console.log("New address created:", createdAddress);
// //     //  const addressToUse = {
// //     //   ...createdAddress, // or just _id if you want
// //     //   type: "NEW",
// //     //  };
// //     //  // 2) Navigate to /cart-summary with the new address
// //     //  navigate("/cart-summary", { state: { address: addressToUse } });
// //     // } else {
// //     //  // If there's an error, you can show an alert or handle it
// //     //  console.error("Error creating address:", resultAction.payload);
// //     //  alert("Error creating address. Please try again.");
// //     // }
// //    } catch (err) {
// //     console.error("Thunk error:", err);
// //    }
// //   }
// //  };

// //  return (
// //   <Box
// //    maxW="600px"
// //    mx="auto"
// //    mt={8}
// //    p={6}
// //    borderWidth={1}
// //    borderRadius="md"
// //    bg="white"
// //   >
// //    <Heading as="h2" size="lg" mb={4}>
// //     Select / Add Address
// //    </Heading>
// //    <Text fontSize="sm" color="gray.600" mb={6}>
// //     Select from saved addresses or add a new address
// //    </Text>

// //    {/* Loading & Error handling */}
// //    {loading && <Text>Loading saved addresses...</Text>}
// //    {error && <Text color="red.500">Error: {error}</Text>}

// //    {/* New Address Form */}
// //    <VStack spacing={4} mb={6}>
// //     <Input
// //      placeholder="Full Name"
// //      name="name"
// //      value={newAddress.name}
// //      onChange={handleAddressChange}
// //     />
// //     <Input
// //      placeholder="House/Flat Number"
// //      name="houseFlatNumber"
// //      value={newAddress.houseFlatNumber}
// //      onChange={handleAddressChange}
// //     />
// //     <Input
// //      placeholder="Complete Address"
// //      name="completeAddress"
// //      value={newAddress.completeAddress}
// //      onChange={handleAddressChange}
// //     />
// //     <Input
// //      placeholder="Pincode"
// //      name="pincode"
// //      value={newAddress.pincode}
// //      onChange={handleAddressChange}
// //     />
// //     <Input
// //      placeholder="Landmark"
// //      name="landmark"
// //      value={newAddress.landmark}
// //      onChange={handleAddressChange}
// //     />
// //    </VStack>

// //    <Divider mb={6} />

// //    {/* Saved Addresses */}
// //    <Heading as="h3" size="md" mb={4}>
// //     Choose from Saved Addresses
// //    </Heading>

// //    <RadioGroup
// //     value={selectedAddressId}
// //     onChange={(value) => setSelectedAddressId(value)}
// //    >
// //     <VStack spacing={4} align="stretch">
// //      {/* Map over saved addresses from Redux */}
// //      {savedAddresses?.map((addr) => (
// //       <Box
// //        key={addr._id}
// //        p={4}
// //        borderWidth={1}
// //        borderRadius="md"
// //        bg={selectedAddressId === addr._id ? "blue.50" : "gray.50"}
// //        borderColor={selectedAddressId === addr._id ? "blue.500" : "gray.200"}
// //       >
// //        <HStack justify="space-between" align="center">
// //         <HStack>
// //          <Icon as={FiMapPin} color="blue.500" boxSize={6} />
// //          <Text fontSize="sm" color="gray.700">
// //           {/* Display relevant address info */}
// //           {addr.name}, {addr.houseFlatNumber}, {addr.completeAddress}, Pincode:{" "}
// //           {addr.pincode}
// //          </Text>
// //         </HStack>
// //         <Radio value={addr._id} />
// //        </HStack>
// //       </Box>
// //      ))}
// //     </VStack>
// //    </RadioGroup>

// //    {/* Footer Button */}
// //    <Button colorScheme="red" w="100%" mt={6} onClick={handleContinue}>
// //     Continue →
// //    </Button>
// //   </Box>
// //  );
// // };

// // export default AddressForm;
