import React, { useEffect, useState } from "react";
import {
 Box,
 Flex,
 IconButton,
 Button,
 Avatar,
 Drawer,
 DrawerBody,
 DrawerOverlay,
 DrawerContent,
 DrawerCloseButton,
 useDisclosure,
 Text,
 Image,
 Heading,
 useBreakpointValue,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Link, useLocation } from "react-router-dom";
import LogoutButton from "../users/LogoutButton";
import { CartButton } from "./CartButton";
import { UserAvatar } from "./UserAvatar";
import { useSelector } from "react-redux";
import CitySelectionModal from "../models/CitySelectionModal";
import AccountAndLists from "./AccountAndLists";
import { FiLogOut, FiSettings } from "react-icons/fi";

const navLinks = [
 { href: "/", label: "Home" },
 { href: "/service", label: "Services" },
 { href: "/about", label: "About" },
 { href: "/Franchisee", label: "Become A Franchise Partner" },
];

const Navbar = () => {
 const location = useLocation();
 const { isOpen, onOpen, onClose } = useDisclosure();
 const { user, isAuth } = useSelector((state) => state.auth);
 const [isVisible, setIsVisible] = useState(true); // Controls navbar visibility
 const [lastScrollY, setLastScrollY] = useState(0); // Tracks last scroll position
 const [isModalOpen, setModalOpen] = useState(false);
 const [selectedCity, setSelectedCity] = useState("Select City");
 const isMobile = useBreakpointValue({ base: true, md: false });
 console.log("user", user);
 //  const handleCitySelect = (city) => {
 //   setSelectedCity(city); // Update selected city
 //   setModalOpen(false); // Close modal
 //  };
 const handleCitySelect = () => {
  if (user?.addresses?.length > 0) {
   // Find the address marked as current
   const currentAddress = user.addresses.find((addr) => addr.currentAddress);

   // If a current address exists, use its city, otherwise use the first address
   const cityToShow = currentAddress
    ? currentAddress.city
    : user.addresses[0].city;

   setSelectedCity(cityToShow);
  }
  setModalOpen(false); // Close modal
 };

 //  useEffect(() => {
 //   const handleScroll = () => {
 //    const currentScrollY = window.scrollY;

 //    if (currentScrollY > lastScrollY) {
 //     setIsVisible(false); // Hide navbar on scroll down
 //    } else {
 //     setIsVisible(true); // Show navbar on scroll up
 //    }

 //    setLastScrollY(currentScrollY);
 //   };

 //   window.addEventListener("scroll", handleScroll);

 //   return () => {
 //    window.removeEventListener("scroll", handleScroll);
 //   };
 //  }, [lastScrollY]);
 //  const location = useLocation();
 useEffect(() => {
  if (location.pathname === "/product-listing-page") return; // Skip visibility logic

  const handleScroll = () => {
   const currentScrollY = window.scrollY;

   if (currentScrollY > lastScrollY) {
    setIsVisible(false); // Hide navbar on scroll down
   } else {
    setIsVisible(true); // Show navbar on scroll up
   }

   setLastScrollY(currentScrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
   window.removeEventListener("scroll", handleScroll);
  };
 }, [lastScrollY, location.pathname]);

 const handleLogout = () => {
  console.log("User logged out");
  // Add your logout logic here
 };
 return (
  //   <Box
  //    bg="#000D6B"
  //    color="white"
  //    position="sticky"
  //    top="0"
  //    zIndex="1000"
  //    transform={isVisible ? "translateY(0)" : "translateY(-100%)"} // Move navbar up/down
  //    transition="transform 0.3s ease-in-out" // Smooth transition effect
  //   >
  <Box
   bg="#000D6B"
   color="white"
   position={location.pathname === "/product-listing-page" ? "fixed" : "sticky"}
   top="0"
   left="0"
   right="0"
   zIndex="1000" // Keep navbar above everything
   transform={
    location.pathname === "/product-listing-page"
     ? "none"
     : isVisible
     ? "translateY(0)"
     : "translateY(-100%)"
   }
   transition="transform 0.3s ease-in-out"
  >
   {location.pathname === "/" && (
    <Box
     display={"flex"}
     alignItems={"center"}
     justifyContent={"space-around"}
     bg="#FFF4E6"
     borderBottom="1px solid #D1D5DB"
     w={{ base: "100%", md: "100%" }}
     h="55px" // Set height to 40px
     //  p={2}
     color={["gray.500", "blue.500", "green.500"]}
    >
     <Flex align="center" gap={2}>
      <Image
       src="./car_expert_logo.svg"
       //    style={{ backgroundColor: "black" }}

       alt="App Logo"
       boxSize="40px"
       borderRadius="md"
      />
      <Box
       w={{ base: "80%", md: "100%" }}
       mt={"2"}
       display="flex"
       flexDirection={"column"}
       alignItems={"center"}
       justifyContent={"center"}
       h="40px"
      >
       <Box textAlign="center" mt="4">
        <Link href="/download" _hover={{ textDecoration: "none" }}>
         <Text
          fontSize={["md", "lg", "1.5rem"]}
          fontWeight="bold"
          mb="0px"
          lineHeight="1"
          color="blue.500"
          cursor="pointer"
          _hover={{
           textDecoration: "underline",
           color: "blue.700",
           transform: "scale(1.1)",
           transition: "transform 0.2s ease-in-out",
          }}
          animation="pulse 2s infinite"
         >
          🚀 Download App
         </Text>
        </Link>
       </Box>

       <Text color="black" fontSize="xs" mt="0px" lineHeight="1">
        Get up to 50% discount on all services in the App.
       </Text>
      </Box>
     </Flex>
     <Box>
      <Image
       src="./playStore.png"
       alt="Google Play Store"
       w={{ base: "100px", md: "100px", lg: "200px" }}
       h={{ base: "40px", md: "40px", lg: "50px" }}
       borderRadius="md"
      />
     </Box>
    </Box>
   )}

   {/* Navbar Section */}
   {!isMobile && (
    <Box
     bg="#000D6B"
     color="white"
     height={{
      base: "66px",
     }}
     width={{
      lg: "70%",
     }}
     m="auto"
     display={"flex"}
     alignItems={"center"}
     justifyContent={"space-between"}
    >
     {/* logo Image  */}
     <Link to="/">
      <Image
       src="/logo1.png"
       alt="Car Logo"
       boxSize={{ base: "50px", md: "60px" }}
       borderRadius="md"
       objectFit="contain"
       _hover={{
        transform: "scale(1.1)",
        transition: "transform 0.3s ease",
       }}
       mx="auto"
       display="block"
      />
     </Link>
     <Box
      bg="#000D6B"
      color="white"
      height={{
       base: "66px",
      }}
      width={{
       lg: "60%",
      }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
     >
      <Button
       onClick={() => setModalOpen(true)}
       variant="solid"
       bg="transparent"
       color="white"
       borderRadius="full"
       fontWeight="bold"
       fontSize="sm"
       _hover={{
        bg: "blue.600",
        transform: "scale(1.05)",
        transition: "all 0.3s ease",
       }}
       _active={{
        bg: "blue.700",
        transform: "scale(1)",
       }}
       _focus={{
        boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.6)",
       }}
       rightIcon={<ChevronDownIcon boxSize={5} />}
      >
       {selectedCity === "Select City" ? "Serving City" : selectedCity}
      </Button>

      {navLinks.map((link) => (
       <Link
        key={link.href}
        to={link.href}
        style={{ textDecoration: "none" }}
        _hover={{ color: "#F99950" }}
       >
        {link.label}
       </Link>
      ))}
      {user && <CartButton />}
      <UserAvatar user={user} />
     </Box>
    </Box>
   )}
   {isMobile && (
    <Box
     //  border={"2px solid red"}
     w="100%"
     display={"flex"}
     alignItems={"center"}
     justifyContent={"space-between"}
    >
     <Box display={"flex"} alignItems="center" justifyContent={"center"}>
      <IconButton
       aria-label="Open Menu"
       icon={<HamburgerIcon w={8} h={10} />}
       display={{ base: "flex", md: "none" }}
       onClick={onOpen}
       variant="ghost"
       color="white"
       size="lg"
       _hover={{
        bg: "gray.700", // Background color on hover
        transition: "background-color 0.3s ease",
       }}
       _active={{
        bg: "gray.600", // Background color when active
       }}
       borderRadius="full"
      />

      <Link to="/">
       <Heading
        size="md"
        mt={"1"}
        color="white"
        _hover={{ textDecoration: "underline" }}
       >
        Car Expert
       </Heading>
      </Link>
     </Box>
     <Box
      display={{ base: "flex", md: "none", lg: "none" }}
      alignItems="center"
      justifyContent={"center"}
      gap={1}
      mt={"10px"}
     >
      {/* Greeting and Account Links */}
      <Stack spacing={0} lineHeight="0.2">
       {user ? (
        <Text fontSize="xs">Hello, {user?.name}</Text>
       ) : (
        <Text fontSize="xs" textAlign="center">
         {"Guest"}
        </Text>
       )}
       <Flex align={"center"} justify={"center"}>
        <Text
         fontSize="sm"
         fontWeight="bold"
         onClick={() => setModalOpen(true)}
        >
         {selectedCity === "Select City" ? "Serving City" : selectedCity}
        </Text>
        <ChevronDownIcon
         onClick={() => setModalOpen(true)}
         mt="-4"
         boxSize={5}
        />
       </Flex>
      </Stack>

      {/* <Avatar
       name={user?.name}
       src={user?.name}
       size="sm"
       borderRadius="100%"
       mb={3}
      /> */}

      <Menu>
       {/* Avatar as the MenuButton to open menu on click */}
       <MenuButton>
        <Avatar
         name={user?.name}
         src={user?.avatar || "/default-avatar.png"}
         size="sm"
         borderRadius="full"
         cursor="pointer" // Ensures it's clickable
         mb={3}
        />
       </MenuButton>

       <MenuList>
        {isAuth && (
         <>
          <MenuItem
           color="black"
           icon={<FiSettings />}
           onClick={() => console.log("Profile clicked")}
          >
           Profile
          </MenuItem>
          <MenuItem icon={<FiLogOut />} color="red.500" onClick={handleLogout}>
           Logout
          </MenuItem>
         </>
        )}
       </MenuList>
      </Menu>
     </Box>
    </Box>
   )}

   {/* Drawer for Mobile View */}
   <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
     <DrawerCloseButton />
     <DrawerBody>
      <Flex direction="column" align="flex-start" mt={6} gap={4}>
       {/* Logo Section */}
       <Flex align="center" mb={4}>
        <Image
         w="50px"
         h="50px"
         borderRadius="full"
         src="./car_expert_logo.svg"
         alt="Car Logo"
        />
        <Text fontWeight="bold" ml={2}>
         Car Expert
        </Text>
       </Flex>

       {/* Navigation Links */}
       {navLinks.map((link) => (
        <Link
         key={link.href}
         to={link.href}
         style={{ textDecoration: "none", width: "100%" }}
         onClick={onClose}
        >
         <Text
          width="100%"
          textAlign="left"
          fontWeight="medium"
          _hover={{ bg: "gray.100", color: "blue.500" }}
         >
          {link.label}
         </Text>
        </Link>
       ))}
       {user && (
        <Box
         display={"flex"}
         alignItems={"center"}
         justifyContent={"space-around"}
         gap={2}
        >
         <CartButton />
         Manage Cart
        </Box>
       )}
       <LogoutButton onLogout={handleLogout} />
      </Flex>
     </DrawerBody>
    </DrawerContent>
   </Drawer>
   <CitySelectionModal isOpen={isModalOpen} onClose={handleCitySelect} />
  </Box>
 );
};

export default Navbar;
