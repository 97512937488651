import React, { useState, useEffect } from "react";
import {
 Box,
 Grid,
 Flex,
 Text,
 Heading,
 IconButton,
 Divider,
 Spinner,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { format, addDays, startOfToday, parse, isBefore } from "date-fns";
import AddressForm from "./AddressForm";

const TimeSlotPicker = () => {
 const [slots, setSlots] = useState([]);
 const [selectedDate, setSelectedDate] = useState(new Date());
 const [selectedTime, setSelectedTime] = useState(null);
 const [startIndex, setStartIndex] = useState(0); // Tracks the starting index of the 5-day range
 const [timer, setTimer] = useState(null); // Countdown timer in seconds
 const [isBooking, setIsBooking] = useState(false); // Prevent double booking

 // Hardcoded userId and city for now
 const userId = "67c2f4f0ca416134c67c681d"; // Replace with real user ID
 const selectedCity = "Delhi"; // Replace if needed

 useEffect(() => {
  // Fetch time slots from the API
  fetch("https://app-api.carexpert.org.in/api/slots")
   .then((res) => res.json())
   .then((data) => setSlots(data))
   .catch((err) => console.error("Error fetching slots:", err));
 }, []);

 useEffect(() => {
  // If timer is running, decrement every second
  if (timer !== null && timer > 0) {
   const interval = setInterval(() => {
    setTimer((prev) => prev - 1);
   }, 1000);
   return () => clearInterval(interval);
  } else if (timer === 0) {
   // Timer expired: Reset selection
   setSelectedTime(null);
   setTimer(null);
   setIsBooking(false);
  }
 }, [timer]);

 const today = startOfToday();
 const dates = Array.from({ length: 30 }, (_, i) => addDays(today, i)); // Generate 30 upcoming dates
 const displayedDates = dates.slice(startIndex, startIndex + 5); // Slice array to show only 5 dates

 const handlePrevious = () => {
  if (startIndex > 0) setStartIndex((prev) => prev - 5);
 };

 const handleNext = () => {
  if (startIndex + 5 < dates.length) setStartIndex((prev) => prev + 5);
 };

 const handleBookSlot = async (slotId) => {
  if (isBooking) return; // Prevent double booking

  setIsBooking(true);
  setSelectedTime(slotId);
  setTimer(300); // Set timer to 5 minutes (300 seconds)

  try {
   const dateStr = format(selectedDate, "yyyy-MM-dd"); // e.g. 2025-03-01

   const response = await fetch(
    `https://app-api.carexpert.org.in/api/cart/${userId}/book-slot`,
    {
     method: "PUT",
     headers: {
      "Content-Type": "application/json",
     },
     body: JSON.stringify({
      slotId,
      city: selectedCity,
      date: dateStr,
     }),
    }
   );

   if (!response.ok) {
    const errorData = await response.json();
    alert(`Error booking slot: ${errorData.message}`);
    setSelectedTime(null);
    setTimer(null);
    setIsBooking(false);
    return;
   }

   const data = await response.json();
   console.log("Booking success. Updated cart:", data);

   alert(
    "Slot booked successfully! You have 5 minutes to complete the process."
   );
  } catch (err) {
   console.error("Error in handleBookSlot:", err);
   alert("An error occurred while booking the slot.");
   setSelectedTime(null);
   setTimer(null);
   setIsBooking(false);
  }
 };

 return (
  <Box>
   <Box maxW="900px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="lg">
    <Heading as="h3" size="lg" textAlign="center" mb={4}>
     📅 Schedule Your Slot
    </Heading>

    {/* Date Selector */}
    <Flex align="center" justify="space-between" mb={4} gap={2}>
     <IconButton
      icon={<ChevronLeftIcon />}
      aria-label="Previous days"
      onClick={handlePrevious}
      isDisabled={startIndex === 0}
      variant="outline"
      size="xs"
      colorScheme="blue"
     />
     <Grid templateColumns="repeat(5, 1fr)" gap={2} w="full" textAlign="center">
      {displayedDates.map((date) => {
       const isSelected = date.toDateString() === selectedDate.toDateString();
       return (
        <Box
         key={date}
         cursor="pointer"
         p={3}
         bg={isSelected ? "blue.500" : "white"}
         border="1px solid"
         borderColor={isSelected ? "blue.500" : "gray.200"}
         borderRadius="md"
         shadow={isSelected ? "md" : "sm"}
         onClick={() => setSelectedDate(date)}
         transition="all 0.2s"
        >
         <Text
          fontSize="sm"
          fontWeight="bold"
          color={isSelected ? "white" : "gray.800"}
         >
          {format(date, "EEE")}
         </Text>
         <Text fontSize="md" color={isSelected ? "white" : "gray.600"}>
          {format(date, "d MMM")}
         </Text>
        </Box>
       );
      })}
     </Grid>
     <IconButton
      icon={<ChevronRightIcon />}
      aria-label="Next days"
      onClick={handleNext}
      isDisabled={startIndex + 5 >= dates.length}
      variant="outline"
      size="xs"
      colorScheme="blue"
     />
    </Flex>

    {/* Time Slots */}
    <Box bg="white" borderRadius="md" p={4} mt={4} shadow="sm">
     <Text mb={2} fontWeight="bold" textAlign="center">
      Select a Time Slot
     </Text>
     <Divider mb={4} />
     <Grid
      templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
      gap={4}
     >
      {slots
       .filter(
        (slot) => slot.time && slot.time.trim() !== "" && slot.time !== "0"
       )
       .map((slot) => {
        const isSelected = selectedTime === slot._id;
        const slotDateTime = parse(slot.time, "h:mm a", selectedDate);
        const isPast = isBefore(slotDateTime, new Date());

        return (
         <Box
          key={slot._id}
          position="relative"
          bg={isSelected ? "blue.50" : "white"}
          border="1px solid"
          borderColor={isSelected ? "blue.500" : "gray.200"}
          borderRadius="md"
          shadow={isSelected ? "md" : "sm"}
          cursor={isPast || isBooking ? "not-allowed" : "pointer"}
          onClick={() => {
           if (!isPast && !isBooking) {
            handleBookSlot(slot._id);
           }
          }}
          p={2}
          textAlign="center"
          w={{ base: "100px", md: "120px" }}
          h="50px"
          transition="all 0.2s"
          opacity={isPast ? 0.5 : 1}
         >
          {slot.discount && (
           <Box position="absolute" top="-8px" left="-8px">
            <Box
             as="span"
             bg="blue.500"
             color="white"
             fontSize="10px"
             fontWeight="bold"
             borderRadius="full"
             px={2}
             py={0.5}
             shadow="sm"
            >
             {slot.discount}% OFF
            </Box>
           </Box>
          )}
          <Text fontSize="sm" fontWeight="bold" color="gray.800" mt={5}>
           {slot.time}
          </Text>
         </Box>
        );
       })}
     </Grid>
    </Box>

    {/* Countdown Timer */}
    {timer !== null && (
     <Flex justify="center" mt={6}>
      <Text fontSize="lg" fontWeight="bold" color="red.500">
       Time left: {Math.floor(timer / 60)}:
       {(timer % 60).toString().padStart(2, "0")}
      </Text>
     </Flex>
    )}
   </Box>
   <AddressForm />
  </Box>
 );
};

export default TimeSlotPicker;

// import React, { useState, useEffect } from "react";
// import {
//  Box,
//  Grid,
//  Flex,
//  Text,
//  Heading,
//  Button,
//  IconButton,
//  Divider,
// } from "@chakra-ui/react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
// import { format, addDays, startOfToday, parse, isBefore } from "date-fns";
// import AddressForm from "./AddressForm";

// const TimeSlotPicker = () => {
//  const [slots, setSlots] = useState([]);
//  const [selectedDate, setSelectedDate] = useState(new Date());
//  const [selectedTime, setSelectedTime] = useState(null);
//  const [startIndex, setStartIndex] = useState(0); // Tracks the starting index of the 5-day range

//  useEffect(() => {
//   // Fetch time slots from the API
//   fetch("https://app-api.carexpert.org.in/api/slots")
//    .then((res) => res.json())
//    .then((data) => setSlots(data))
//    .catch((err) => console.error("Error fetching slots:", err));
//  }, []);

//  const today = startOfToday();
//  const dates = Array.from({ length: 30 }, (_, i) => addDays(today, i)); // Generate 30 upcoming dates
//  const displayedDates = dates.slice(startIndex, startIndex + 5); // Slice the array to show only 5 dates

//  const handlePrevious = () => {
//   if (startIndex > 0) setStartIndex((prev) => prev - 5);
//  };

//  const handleNext = () => {
//   if (startIndex + 5 < dates.length) setStartIndex((prev) => prev + 5);
//  };

//  const handleBookSlot = (slotId) => {
//   setSelectedTime(slotId);
//  };

//  return (
//   <Box>
//    <Box maxW="900px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="lg">
//     <Heading as="h3" size="lg" textAlign="center" mb={4}>
//      📅 Schedule Your Slot
//     </Heading>

//     {/* Date Selector */}
//     <Flex align="center" justify="space-between" mb={4} gap={2}>
//      <IconButton
//       icon={<ChevronLeftIcon />}
//       aria-label="Previous days"
//       onClick={handlePrevious}
//       isDisabled={startIndex === 0}
//       variant="outline"
//       size="xs"
//       colorScheme="blue"
//      />
//      <Grid templateColumns="repeat(5, 1fr)" gap={2} w="full" textAlign="center">
//       {displayedDates.map((date) => {
//        const isSelected = date.toDateString() === selectedDate.toDateString();
//        return (
//         <Box
//          key={date}
//          cursor="pointer"
//          p={3}
//          bg={isSelected ? "blue.500" : "white"}
//          border="1px solid"
//          borderColor={isSelected ? "blue.500" : "gray.200"}
//          borderRadius="md"
//          shadow={isSelected ? "md" : "sm"}
//          onClick={() => setSelectedDate(date)}
//          transition="all 0.2s"
//         >
//          <Text
//           fontSize="sm"
//           fontWeight="bold"
//           color={isSelected ? "white" : "gray.800"}
//          >
//           {format(date, "EEE")}
//          </Text>
//          <Text fontSize="md" color={isSelected ? "white" : "gray.600"}>
//           {format(date, "d MMM")}
//          </Text>
//         </Box>
//        );
//       })}
//      </Grid>
//      <IconButton
//       icon={<ChevronRightIcon />}
//       aria-label="Next days"
//       onClick={handleNext}
//       isDisabled={startIndex + 5 >= dates.length}
//       variant="outline"
//       size="xs"
//       colorScheme="blue"
//      />
//     </Flex>

//     {/* Time Slots */}
//     <Box bg="white" borderRadius="md" p={4} mt={4} shadow="sm">
//      <Text mb={2} fontWeight="bold" textAlign="center">
//       Select a Time Slot
//      </Text>
//      <Divider mb={4} />
//      <Grid
//       templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
//       gap={4}
//      >
//       {slots
//        .filter(
//         (slot) => slot.time && slot.time.trim() !== "" && slot.time !== "0"
//        ) // Exclude invalid values
//        .map((slot) => {
//         const isSelected = selectedTime === slot._id;

//         // Parse the slot time and check if it's in the past
//         const slotDateTime = parse(slot.time, "h:mm a", selectedDate);
//         const isPast = isBefore(slotDateTime, new Date());

//         return (
//          <Box
//           key={slot._id}
//           position="relative"
//           bg={isSelected ? "blue.50" : "white"}
//           border="1px solid"
//           borderColor={isSelected ? "blue.500" : "gray.200"}
//           borderRadius="md"
//           shadow={isSelected ? "md" : "sm"}
//           cursor={isPast ? "not-allowed" : "pointer"}
//           onClick={() => {
//            if (!isPast) {
//             handleBookSlot(slot._id);
//            }
//           }}
//           p={2}
//           textAlign="center"
//           w={{ base: "100px", md: "120px" }}
//           h="50px"
//           transition="all 0.2s"
//           opacity={isPast ? 0.5 : 1} // Dim past slots
//          >
//           {/* Badge for Discount */}
//           {slot.discount && (
//            <Box position="absolute" top="-8px" left="-8px">
//             <Box
//              as="span"
//              bg="blue.500"
//              color="white"
//              fontSize="10px"
//              fontWeight="bold"
//              borderRadius="full"
//              px={2}
//              py={0.5}
//              shadow="sm"
//             >
//              {slot.discount}% OFF
//             </Box>
//            </Box>
//           )}
//           {/* Slot Time */}
//           <Flex direction="column" align="center" justify="center" h="full">
//            <Text fontSize="sm" fontWeight="bold" color="gray.800" mt={5}>
//             {slot.time}
//            </Text>
//           </Flex>
//          </Box>
//         );
//        })}
//      </Grid>
//     </Box>

//     {/* Next Button */}
//     {selectedTime && (
//      <Flex justify="center" mt={6}>
//       <Button colorScheme="blue" size="lg">
//        Next
//       </Button>
//      </Flex>
//     )}
//    </Box>
//    <AddressForm />
//   </Box>
//  );
// };

// export default TimeSlotPicker;
