import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box, Heading, Text, Image, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchSparePartsProducts,
 fetchTrendingProducts,
} from "../../redux/tranding";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { addToCart, fetchCart } from "../../redux/cartSlice";

const OriginalSpareParts = () => {
 const dispatch = useDispatch();
 const { cart } = useSelector((state) => state.cart);
 const { user, isAuth } = useSelector((state) => state.auth);
 const { spareParts, loading, error } = useSelector((state) => state.trending);

 useEffect(() => {
  dispatch(fetchTrendingProducts());
  dispatch(fetchSparePartsProducts());
 }, [dispatch]);

 // Ensure spareParts has valid data before setting up the slider
 const slidesToShow = spareParts.length < 3 ? spareParts.length : 3;

 const settings = {
  dots: true,
  infinite: spareParts.length > 3, // Enable infinite scroll only if there are more than 3 items
  speed: 500,
  slidesToShow: slidesToShow, // Adjust dynamically
  slidesToScroll: 1,
  arrows: true,
  responsive: [
   {
    breakpoint: 1024,
    settings: { slidesToShow: slidesToShow },
   },
   {
    breakpoint: 768,
    settings: { slidesToShow: spareParts.length < 2 ? spareParts.length : 2 },
   },
   {
    breakpoint: 480,
    settings: { slidesToShow: 1 },
   },
  ],
 };

 const handleAddToCart = (product) => {
  if (!user) return;
  dispatch(
   addToCart({
    productId: product._id,
    subCategoryId: product.SubCategory,
    currentCarId: user.currentCar._id,
    userId: user._id,
    ProductType: "SparePart",
   })
  );
  dispatch(fetchCart(user._id));
 };

 const isProductInCart = (productId) => {
  return cart?.items?.some((item) => item.productId === productId);
 };

 if (loading) return <Text>Loading...</Text>;
 if (error) return <Text>Error loading products: {error}</Text>;

 return (
  <Box mt={10} w="90%" mx="auto" lineHeight={"10px"}>
   <Heading as="h6" size="md" mb={4} textAlign="center">
    Spare Parts
   </Heading>
   <Text fontSize="lg" textAlign="center">
    Extended Warranty with Car Expert
   </Text>
   <Text fontSize="lg" textAlign="center">
    Original Spare Parts
   </Text>

   {/* Slider Component */}
   <Slider {...settings}>
    {spareParts.map((sparePart, index) => (
     <Box
      key={sparePart._id || index}
      position="relative"
      w="100%" // Allow proper width calculation
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      mx="5px"
      p={2}
     >
      {/* Image Section */}
      <Image
       src={sparePart.image || "https://via.placeholder.com/200?text=No+Image"}
       alt={sparePart.spareName}
       objectFit="cover"
       w="100%"
       h="60px"
       borderRadius="md"
      />

      {/* Content */}
      <Box textAlign="center" mt={2} lineHeight={"10px"}>
       <Text fontWeight="bold">{sparePart.spareName}</Text>
       <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"5px"}
       >
        <Text color="gray.500" fontSize="sm" as="s" mt="-15px">
         ₹{sparePart.mrp}
        </Text>
        <Text fontWeight="bold" color="green.500" fontSize="md">
         ₹{sparePart.price}
        </Text>
       </Box>
       {isAuth ? (
        <Link to="/product-listing-page">
         <Button
          colorScheme="blue"
          w="100px"
          size="sm"
          _hover={{ bg: "blue.600" }}
          onClick={() => handleAddToCart(sparePart)}
          mt={1}
         >
          {isProductInCart(sparePart._id) ? "In Cart" : "Buy"}
         </Button>
        </Link>
       ) : (
        <Button
         colorScheme="teal"
         variant="outline"
         size="sm"
         _hover={{ bg: "teal.100" }}
         mt={1}
        >
         Check Price
        </Button>
       )}
      </Box>
     </Box>
    ))}
   </Slider>
  </Box>
 );
};

export default OriginalSpareParts;
