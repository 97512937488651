import React, { useEffect, useState } from "react";
import {
 Box,
 Image,
 Text,
 Button,
 VStack,
 HStack,
 Divider,
 Flex,
 Avatar,
 Accordion,
 AccordionItem,
 AccordionButton,
 AccordionIcon,
 AccordionPanel,
 Radio,
 RadioGroup,
 Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
 applyCoupon,
 fetchCart,
 fetchCouponCodes,
 removeProductFromCart,
 updateCartItemQuantity,
} from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";
import OriginalSpareParts from "./OriginalSpareParts";
import { format } from "date-fns";

const CartCard = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const { cart, couponCodes } = useSelector((state) => state.cart);
 const { selectedCar } = useSelector((state) => state.models);
 const { user } = useSelector((state) => state.auth);

 const [visibleItems, setVisibleItems] = useState(2);
 const [selectedCoupon, setSelectedCoupon] = useState(cart?.couponCode || "");

 // Get cart items from Redux
 const items = Array.isArray(cart?.items) ? cart.items : [];

 // Calculate total amount
 const totalAmount = items.reduce(
  (acc, item) => acc + item.price * (item.quantity || 1),
  0
 );

 // Fetch cart & coupons on mount
 useEffect(() => {
  if (user?._id) {
   dispatch(fetchCart(user._id));
  }
  if (couponCodes.length === 0) {
   dispatch(fetchCouponCodes());
  }
 }, [dispatch, user?._id]);

 useEffect(() => {
  const savedCoupon = localStorage.getItem("selectedCoupon");

  // Check if cart has a valid couponCode matching the available coupon list
  if (
   cart?.couponCode &&
   couponCodes.some((coupon) => coupon.code === cart.couponCode)
  ) {
   setSelectedCoupon(cart.couponCode);
   localStorage.setItem("selectedCoupon", cart.couponCode);
  } else if (
   savedCoupon &&
   couponCodes.some((coupon) => coupon.code === savedCoupon)
  ) {
   setSelectedCoupon(savedCoupon);
  } else {
   setSelectedCoupon(""); // Reset if no valid coupon found
  }
 }, [cart?.couponCode, couponCodes]);

 // Fetch cart & coupons on mount
 useEffect(() => {
  if (user?._id) {
   dispatch(fetchCart(user._id));
   dispatch(fetchCouponCodes());
  }
 }, [dispatch, user?._id]);

 // Apply coupon
 const handleApplyCoupon = (couponCode) => {
  setSelectedCoupon(couponCode);
  localStorage.setItem("selectedCoupon", couponCode);
  dispatch(applyCoupon({ userId: user?._id, couponCode })).then(() => {
   dispatch(fetchCart(user._id));
  });
 };

 console.log("cart:", cart);

 const handleRemove = (item) => {
  console.log("Removing item:", item); // Debugging log

  if (!item || !item._id) {
   console.error("Error: Missing productId", item);
   return;
  }

  if (!user?._id) {
   console.error("Error: Missing userId");
   return;
  }

  dispatch(
   removeProductFromCart({
    userId: user._id,
    productId: item?.productId, // Ensure correct ID is sent
    subCategoryId: item.subCategoryId || null,
   })
  )
   .unwrap()
   .then(() => {
    console.log("Item removed successfully");
    dispatch(fetchCart(user._id)); // Refresh cart
   })
   .catch((err) => console.error("Error removing item:", err));
 };

 const handleQuantityChange = (item, delta) => {
  if (item?.type !== "SparePart") return; // Only allow quantity updates for SpareParts

  const newQuantity = item.quantity + delta;
  if (newQuantity < 1) return; // Prevent negative quantity

  dispatch(
   updateCartItemQuantity({
    userId: user?._id,
    productId: item.productId,
    subCategoryId: item.subCategoryId,
    quantity: newQuantity,
   })
  ).then(() => {
   dispatch(fetchCart(user?._id)); // Refresh cart after updating quantity
  });
 };

 // Show more/less items
 const handleToggle = () => {
  setVisibleItems((prev) => (prev < items.length ? items.length : 2));
 };

 return (
  <Box bg="white" w="100%" p={0} borderWidth={1} borderRadius="lg" shadow="lg">
   <Box
    bg="gray.50"
    border={"1px solid black"}
    w="100%"
    display="flex"
    alignItems="center"
    justifyContent={"space-around"}
    h="80px"
    mb={10}
    borderRadius="10px"
   >
    <Box
     mt={5}
     display={"flex"}
     flexDirection={"column"}
     alignItems={"center"}
     justifyContent={"center"}
     // gap="2"

     lineHeight={"2px"}
    >
     <Text fontSize="sm" fontWeight="bold">
      {selectedCar?.brand?.title}
     </Text>
     <Text fontSize="sm" fontWeight="bold">
      {selectedCar?.title}
     </Text>
     <Text fontSize="sm" fontWeight="bold">
      {selectedCar?.fuelType}
     </Text>
    </Box>
    <Avatar
     name={selectedCar?.title}
     src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2pktNjdF4JnsdLnD4GG9Zg8CalTYWyz25PA&s"
     borderRadius="0"
     boxSize="70px"
    />
   </Box>

   {/* Cart Items */}
   <VStack spacing={4} align="stretch" mb={6}>
    {items.length === 0 ? (
     <Text textAlign="center" fontSize="lg" color="gray.500">
      No items in cart
     </Text>
    ) : (
     items.slice(0, visibleItems).map((item) => (
      <VStack
       key={item._id}
       p={4}
       borderWidth={1}
       borderRadius="md"
       shadow="sm"
       bg="gray.50"
      >
       <HStack spacing={4} w="100%">
        {/* Product Details */}
        <VStack align="start" spacing={2} flex="2">
         <Text fontWeight="bold" fontSize="lg">
          {/* Product: {item?.productDetails?.productName || "Unknown"} */}
          {item?.type === "Product"
           ? item?.productDetails?.productName
           : item?.productDetails?.spareName || "Unknown"}
         </Text>

         <Text color="gray.600">Price: ₹{item.price}</Text>

         {/* Quantity Controls */}
         {item?.type !== "Product" && (
          // <HStack spacing={3} mt={2}>
          //  <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
          //   -
          //  </Button>
          //  <Text fontWeight="bold">{item.quantity}</Text>
          //  <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
          //   +
          //  </Button>
          // </HStack>
          <HStack spacing={3} mt={2}>
           <Button size="sm" onClick={() => handleQuantityChange(item, -1)}>
            -
           </Button>
           <Text fontWeight="bold">{item.quantity}</Text>
           <Button size="sm" onClick={() => handleQuantityChange(item, 1)}>
            +
           </Button>
          </HStack>
         )}

         {/* Remove Button */}
         <Button
          variant="link"
          colorScheme="red"
          size="sm"
          onClick={() => handleRemove(item)}
         >
          Remove
         </Button>
        </VStack>

        {/* Product Image */}
        <Image
         boxSize="100px"
         //  src={item?.productDetails?.productImage || "/placeholder-image.png"}
         src={
          item?.type === "Product"
           ? item?.productDetails?.productImage
           : item?.productDetails?.image || "/placeholder-image.png"
         }
         alt="Product"
         fallbackSrc="/placeholder-image.png"
        />
       </HStack>
      </VStack>
     ))
    )}

    {items.length > 2 && (
     <Button
      colorScheme="blue"
      size="sm"
      variant="outline"
      onClick={handleToggle}
     >
      {visibleItems < items.length ? "Show More" : "Show Less"}
     </Button>
    )}
   </VStack>

   {/* Coupons Section */}
   <OriginalSpareParts />
   <Divider mb={4} />

   <VStack align="stretch" w="100%" spacing={2}>
    <Accordion allowToggle>
     <AccordionItem borderWidth="1px" borderRadius="md" borderColor="gray.300">
      <h2>
       <AccordionButton>
        <Box flex="1" textAlign="left" fontWeight="bold">
         Available Coupons ({couponCodes.length})
        </Box>
        <AccordionIcon />
       </AccordionButton>
      </h2>

      <AccordionPanel pb={4}>
       <RadioGroup onChange={handleApplyCoupon} value={selectedCoupon}>
        <Stack spacing={3}>
         {couponCodes.map((coupon) => (
          <Flex
           key={coupon._id}
           w="350px"
           h="180px"
           bg={selectedCoupon === coupon.code ? "blue.900" : "gray.800"}
           color="white"
           borderRadius="10px"
           overflow="hidden"
           position="relative"
           alignItems="center"
           transition="background 0.3s ease"
           boxShadow="lg"
          >
           {/* Left Section - Discount & Subcategory Image */}
           <Box
            w={{ base: "80px", md: "120px" }}
            h="full"
            bg="gray.100"
            color="black"
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            fontWeight="bold"
            borderRight="3px dashed black"
           >
            <Image
             src={coupon.subCategory.subCategoryImage}
             alt={coupon.subCategory.subCategoryName}
             w="90%"
             h="60px"
             objectFit="cover"
             borderTopRadius="5px"
            />
            <Text fontSize="20px" mt="5px">
             {coupon.discountPercent}%
            </Text>
            <Text fontSize="12px">{coupon.type.toUpperCase()}</Text>
           </Box>

           {/* Right Section - Coupon Details */}
           <Box flex="1" px="20px" textAlign="center">
            <Text fontSize="14px" color="yellow.400" mt="10px">
             {coupon.title}
            </Text>
            <Text
             fontSize={{ base: "15px", md: "", lg: "24px" }}
             fontWeight="bold"
             mt={-5}
            >
             {coupon.code}
            </Text>
            <Text fontSize="12px" color="gray.300">
             {coupon.description}
            </Text>
            <Divider borderColor="yellow.500" my="5px" />
            <Text fontSize="12px">
             Valid until:{" "}
             <Text as="span" fontWeight="bold" color="yellow.400">
              {format(new Date(coupon.expiryDate), "MMMM dd, yyyy")}
             </Text>
            </Text>

            {/* Radio Selection (Auto-Applies Coupon) */}
            <Flex justify="center" mt="-5px">
             <Radio value={coupon.code}>Select</Radio>
            </Flex>
           </Box>
          </Flex>
         ))}
        </Stack>
       </RadioGroup>
      </AccordionPanel>
     </AccordionItem>
    </Accordion>
    {/* Order Summary */}
    <Flex justify="space-between">
     <Text fontWeight="bold">Subtotal:</Text>
     <Text>₹{totalAmount}</Text>
    </Flex>
    <Flex justify="space-between">
     <Text fontWeight="bold">
      {cart?.cashbackAmount > 0
       ? "Cashback:"
       : cart?.couponDiscount > 0
       ? "Discount:"
       : "Discount:"}
     </Text>

     <Text>
      - ₹
      {cart?.cashbackAmount
       ? cart.cashbackAmount
       : cart?.couponDiscount
       ? cart.couponDiscount
       : 0}
     </Text>
    </Flex>
    <Flex justify="space-between" fontWeight="bold" fontSize="lg">
     <Text>Total:</Text>
     <Text>₹{cart?.finalPrice?.toFixed(2)}</Text>
    </Flex>

    {/* Place Order */}
    <Button
     colorScheme="red"
     w="100%"
     mt={4}
     size="lg"
     onClick={() => navigate("/slot-booking")}
    >
     Place Order
    </Button>
   </VStack>
  </Box>
 );
};

export default CartCard;
