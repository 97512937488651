import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = "https://app-api.carexpert.org.in/api";

export const fetchCart = createAsyncThunk("cart/fetchCart", async (userId) => {
 const response = await axios.get(`${API_BASE_URL}/cart/${userId}`);

 return response.data;
});

// export const addToCart = createAsyncThunk(
//  "cart/addToCart",
//  async ({ productId, subCategoryId, currentCarId, userId }) => {
//   console.log("cartItem", productId, subCategoryId, currentCarId, userId);
//   const response = await axios.post(
//    `${API_BASE_URL}/cart/${userId}/add`,
//    productId,
//    subCategoryId,
//    currentCarId
//   );
//   return response.data;
//  }
// );
export const fetchCouponCodes = createAsyncThunk(
 "coupons/fetchCouponCodes",
 async () => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/coupon-codes"
  );
  return response.data; // Return the data
 }
);
export const removeProductFromCart = createAsyncThunk(
 "cart/removeProductFromCart",
 async ({ userId, productId, subCategoryId }, { rejectWithValue }) => {
  try {
   const response = await axios.post(`${API_BASE_URL}/cart/${userId}/remove`, {
    productId,
    subCategoryId,
   });

   // Return the updated cart
   return response.data;
  } catch (error) {
   // Reject the promise with an error message
   return rejectWithValue(
    error.response.data.message || "Failed to remove product"
   );
  }
 }
);

export const addToCart = createAsyncThunk(
 "cart/addToCart",
 async ({ productId, subCategoryId, currentCarId, userId, ProductType }) => {
  try {
   // Log payload to ensure correctness
   console.log("Sending Payload:", {
    productId,
    subCategoryId,
    carId: currentCarId,
   });

   const response = await axios.post(`${API_BASE_URL}/cart/${userId}/add`, {
    productId, // Correctly include the fields
    subCategoryId,
    carId: currentCarId, // Rename currentCarId to carId for API compatibility
    type: ProductType,
   });

   return response.data;
  } catch (error) {
   console.error("Error adding to cart:", error.message);
   throw error;
  }
 }
);
export const updateCartItemQuantity = createAsyncThunk(
 "cart/updateCartItemQuantity",
 async (
  { userId, productId, subCategoryId, quantity },
  { rejectWithValue }
 ) => {
  try {
   const response = await axios.put(`${API_BASE_URL}/cart/${userId}/update`, {
    productId,
    subCategoryId,
    quantity,
   });
   return response.data;
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Failed to update cart item quantity"
   );
  }
 }
);

export const applyCoupon = createAsyncThunk(
 "cart/applyCoupon",
 async ({ userId, couponCode }) => {
  console.log("check:", userId, couponCode);
  const response = await axios.put(
   `${API_BASE_URL}/cart/${userId}/apply-coupon`,
   //    `http://192.168.1.11:8900/api/cart/${userId}/apply-coupon`,
   { couponCode }
  );
  return response.data;
 }
);

export const bookSlotThunk = createAsyncThunk(
 "cart/bookSlot", // action type
 async ({ userId, slotId, city, date }, { rejectWithValue }) => {
  try {
   // Example: base URL => http://localhost:5000/api/cart (adjust to your setup)
   const response = await axios.put(
    `https://app-api.carexpert.org.in/api/cart/${userId}/book-slot`,
    {
     slotId,
     city,
     date,
    }
   );
   return response.data; // This will be the updated cart
  } catch (error) {
   // `error.response.data` should contain the { message: "..."} from the backend
   return rejectWithValue(error.response?.data || error.message);
  }
 }
);
export const fetchUserAddresses = createAsyncThunk(
 "userAddress/fetchUserAddresses",
 async (userId, { rejectWithValue }) => {
  try {
   // Make the GET request
   const response = await fetch(
    `https://app-api.carexpert.org.in/api/orders/address/${userId}`
   );
   if (!response.ok) {
    const message = `Error: ${response.status} ${response.statusText}`;
    throw new Error(message);
   }

   const data = await response.json();
   console.log("data:", data);
   return data; // This will be your array of addresses
  } catch (err) {
   // Pass the error message to the rejected action
   return rejectWithValue(err.message);
  }
 }
);
export const fetchBookingFee = createAsyncThunk(
 "order/fetchBookingFee",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(`${API_BASE_URL}/bookingFee`);
   return response.data.amount; // Assuming { amount: 200 }
  } catch (err) {
   return rejectWithValue(
    err.response?.data?.message || "Failed to fetch booking fee"
   );
  }
 }
);
export const fetchCODVisibility = createAsyncThunk(
 "order/fetchCODVisibility",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(
    `${API_BASE_URL}/admin-settings/cod-visibility`
   );
   return response.data.showCOD; // Assuming { showCOD: true/false }
  } catch (err) {
   return rejectWithValue(
    err.response?.data?.message || "Failed to fetch COD visibility"
   );
  }
 }
);
export const createOrderThunk = createAsyncThunk(
 "order/createOrder",
 async ({ userId, payload }, { rejectWithValue }) => {
  try {
   const response = await axios.post(
    `${API_BASE_URL}/orders/${userId}`,
    payload
   );
   return response.data; // Order data from backend
  } catch (err) {
   return rejectWithValue(
    err.response?.data?.message || "Failed to create order"
   );
  }
 }
);

const cartSlice = createSlice({
 name: "cart",
 initialState: {
  cart: null,
  couponCodes: [],
  savedAddresses: [],
  bookingFee: 0,
  showCOD: true,
  order: null,
  loading: false,
  error: null,
 },
 reducers: {
  clearCartState(state) {
   state.cart = null;
   state.loading = false;
   state.error = null;
  },
  clearOrderState: (state) => {
   state.order = null;
   state.loading = false;
   state.error = null;
  },
 },
 extraReducers: (builder) => {
  // Fetch Cart
  builder.addCase(fetchCart.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(fetchCart.fulfilled, (state, action) => {
   state.loading = false;
   state.cart = action.payload.cart;
  });
  builder.addCase(fetchCart.rejected, (state, action) => {
   state.loading = false;
   state.error = action.error.message;
  });

  // Add to Cart
  builder.addCase(addToCart.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(addToCart.fulfilled, (state, action) => {
   state.loading = false;
   state.cart = action.payload;
  });
  builder.addCase(addToCart.rejected, (state, action) => {
   state.loading = false;
   state.error = action.error.message;
  });

  // Apply Coupon
  builder.addCase(applyCoupon.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(applyCoupon.fulfilled, (state, action) => {
   state.loading = false;
   state.cart = action.payload.cart;
  });
  builder.addCase(applyCoupon.rejected, (state, action) => {
   state.loading = false;
   state.error = action.error.message;
  });

  // Book Slot

  builder

   // Handle Update Cart Item Quantity
   .addCase(updateCartItemQuantity.pending, (state) => {
    state.status = "loading";
   })
   .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
    state.status = "succeeded";
    state.cart = action.payload;
   })
   .addCase(updateCartItemQuantity.rejected, (state, action) => {
    state.status = "failed";
    state.error = action.payload;
   })
   .addCase(removeProductFromCart.pending, (state) => {
    state.status = "loading";
   })
   .addCase(removeProductFromCart.fulfilled, (state, action) => {
    state.status = "succeeded";
    state.cart = action.payload; // Update the cart with the response data

    // Show success toast
   })
   .addCase(removeProductFromCart.rejected, (state, action) => {
    state.status = "failed";
    state.error = action.payload;

    // Show error toast
   });
  builder
   .addCase(fetchCouponCodes.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchCouponCodes.fulfilled, (state, action) => {
    state.loading = false;
    state.couponCodes = action.payload;
   })
   .addCase(fetchCouponCodes.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })
   // When the thunk is pending (in flight)
   .addCase(bookSlotThunk.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   // If the thunk resolves successfully
   .addCase(bookSlotThunk.fulfilled, (state, action) => {
    state.loading = false;
    state.cart = action.payload; // The updated cart from the backend
    state.error = null;
   })
   // If the thunk is rejected with an error
   .addCase(bookSlotThunk.rejected, (state, action) => {
    state.loading = false;
    // action.payload should have the error message from rejectWithValue
    state.error = action.payload || "Something went wrong";
   });
  builder
   // Pending
   .addCase(fetchUserAddresses.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   // Fulfilled
   .addCase(fetchUserAddresses.fulfilled, (state, action) => {
    state.loading = false;
    state.savedAddresses = action.payload; // The array of addresses
   })
   // Rejected
   .addCase(fetchUserAddresses.rejected, (state, action) => {
    state.loading = false;
    // action.payload is set by rejectWithValue in the thunk
    state.error = action.payload || "Something went wrong";
   });
  builder.addCase(fetchBookingFee.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(fetchBookingFee.fulfilled, (state, action) => {
   state.loading = false;
   state.bookingFee = action.payload;
  });
  builder.addCase(fetchBookingFee.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // Fetch COD Visibility
  builder.addCase(fetchCODVisibility.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(fetchCODVisibility.fulfilled, (state, action) => {
   state.loading = false;
   state.showCOD = action.payload;
  });
  builder.addCase(fetchCODVisibility.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // Create Order
  builder.addCase(createOrderThunk.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(createOrderThunk.fulfilled, (state, action) => {
   state.loading = false;
   state.order = action.payload;
  });
  builder.addCase(createOrderThunk.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });
 },
});

export const { clearCartState } = cartSlice.actions;

export default cartSlice.reducer;
