import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartCard from "../UI/CartCardPage";
import {
 Box,
 Spinner,
 Flex,
 useBreakpointValue,
 Heading,
} from "@chakra-ui/react";
import {
 addToCart,
 fetchCart,
 removeProductFromCart,
} from "../../redux/cartSlice";

import MobileCartBar from "./MobileCartBar";
import ListingPage from "./ListingPage";

const ProductListingPage = () => {
 const dispatch = useDispatch();
 const { user } = useSelector((state) => state.auth);
 const { cart, couponCodes } = useSelector((state) => state.cart);
 const isMobile = useBreakpointValue({ base: true, md: false });
 const [expandedCard, setExpandedCard] = useState(null);

 const toggleShowMore = (productId) => {
  setExpandedCard((prev) => (prev === productId ? null : productId));
 };

 const { currentCar: { _id: currentCarId } = {}, _id: userId = null } =
  user || {};
 console.log("userId:", userId);
 const handleAddToCart = (product, ProductType) => {
  //   console.log("product, ProductType", product, ProductType);
  const {
   _id: productId,
   subCategoryId: { _id: subCategoryId },
  } = product;

  if (
   (productId && subCategoryId && currentCarId) ||
   ("12345" && userId && ProductType)
  ) {
   dispatch(
    addToCart({ productId, subCategoryId, currentCarId, userId, ProductType })
   );
   dispatch(fetchCart(userId));
  } else {
   console.error("Missing required fields:", {
    productId,
    subCategoryId,
    currentCarId,
    userId,
   });
  }
 };

 const isProductInCart = (productId) => {
  return cart?.items?.some((item) => item.productId === productId);
 };

 return (
  <Flex
   direction={{ base: "column", md: "row" }}
   w="90%"
   justify="center"
   align="flex-start"
   gap={6}
   m="auto"
   //    bg={"transparent"}
  >
   {/* Left Section - Listing */}
   <Box
    w={{ base: "100%", md: "70%" }}
    overflowY="auto"
    maxH="100vh"
    sx={{
     scrollbarWidth: "none", // Firefox
     "&::-webkit-scrollbar": { display: "none" }, // Chrome, Safari
    }}
   >
    {/* {loading && <Spinner size="lg" />} */}
    <ListingPage
     expandedCard={expandedCard}
     toggleShowMore={toggleShowMore}
     handleAddToCart={handleAddToCart}
     isProductInCart={isProductInCart}
    />
   </Box>

   {/* Right Section - Cart */}
   <Box w={{ base: "100%", md: "35%" }} mt={{ base: 6, md: 100 }}>
    {cart?.items?.length > 0 && isMobile ? (
     <MobileCartBar cartItems={cart?.items} coupon={couponCodes} />
    ) : (
     <Box
      position="sticky"
      top="100px"
      width="100%"
      maxHeight="calc(100vh - 120px)"
      overflowY="auto"
      transition="all 0.3s ease-in-out"
      sx={{
       scrollbarWidth: "none", // Firefox
       "&::-webkit-scrollbar": { display: "none" }, // Chrome, Safari
      }}
     >
      <Heading as="h3" size="md" textAlign="center">
       Your Cart
      </Heading>
      <CartCard cartItems={cart?.items} coupon={couponCodes} />
     </Box>
    )}
   </Box>
  </Flex>
 );
};

export default ProductListingPage;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import CartCard from "../UI/CartCardPage";
// import {
//  Box,
//  Spinner,
//  Flex,
//  useBreakpointValue,
//  Heading,
// } from "@chakra-ui/react";
// import {
//  addToCart,
//  fetchCart,
//  removeProductFromCart,
// } from "../../redux/cartSlice";

// import MobileCartBar from "./MobileCartBar";
// import ListingPage from "./ListingPage";

// const ProductListingPage = () => {
//  const dispatch = useDispatch();
//  const { user } = useSelector((state) => state.auth);
//  const { cart, couponCodes } = useSelector((state) => state.cart);
//  const isMobile = useBreakpointValue({ base: true, md: false });
//  const [expandedCard, setExpandedCard] = useState(null);

//  const toggleShowMore = (productId) => {
//   setExpandedCard((prev) => (prev === productId ? null : productId));
//  };

//  const { currentCar: { _id: currentCarId } = {}, _id: userId = null } =
//   user || {};
//  console.log("userId:", userId);
//  const handleAddToCart = (product, ProductType) => {
//   //   console.log("product, ProductType", product, ProductType);
//   const {
//    _id: productId,
//    subCategoryId: { _id: subCategoryId },
//   } = product;

//   if (
//    (productId && subCategoryId && currentCarId) ||
//    ("12345" && userId && ProductType)
//   ) {
//    dispatch(
//     addToCart({ productId, subCategoryId, currentCarId, userId, ProductType })
//    );
//    dispatch(fetchCart(userId));
//   } else {
//    console.error("Missing required fields:", {
//     productId,
//     subCategoryId,
//     currentCarId,
//     userId,
//    });
//   }
//  };

//  const isProductInCart = (productId) => {
//   return cart?.items?.some((item) => item.productId === productId);
//  };

//  return (
//   <Flex
//    direction={{ base: "column", md: "row" }}
//    w="90%"
//    justify="center"
//    align="flex-start"
//    gap={6}
//    m="auto"
//    //    bg={"transparent"}
//   >
//    {/* Left Section - Listing */}
//    <Box
//     w={{ base: "100%", md: "70%" }}
//     overflowY="auto"
//     maxH="100vh"
//     sx={{
//      scrollbarWidth: "none", // Firefox
//      "&::-webkit-scrollbar": { display: "none" }, // Chrome, Safari
//     }}
//    >
//     {/* {loading && <Spinner size="lg" />} */}
//     <ListingPage
//      expandedCard={expandedCard}
//      toggleShowMore={toggleShowMore}
//      handleAddToCart={handleAddToCart}
//      isProductInCart={isProductInCart}
//     />
//    </Box>

//    {/* Right Section - Cart */}
//    <Box w={{ base: "100%", md: "35%" }} mt={{ base: 6, md: 100 }}>
//     {isMobile ? (
//      <MobileCartBar
//       cartItems={cart?.items}
//       coupon={couponCodes}
//       //   handleQuantityChange={handleQuantityChange}
//       //   handleRemove={handleRemove}
//      />
//     ) : (
//      <Box
//       //   ref={cartRef}
//       position="sticky"
//       top="100px"
//       width="100%"
//       maxHeight="calc(100vh - 120px)"
//       overflowY="auto"
//       transition="all 0.3s ease-in-out"
//       sx={{
//        scrollbarWidth: "none", // Firefox
//        "&::-webkit-scrollbar": { display: "none" }, // Chrome, Safari
//       }}
//      >
//       <Heading as="h3" size="md" textAlign="center">
//        Your Cart
//       </Heading>
//       <CartCard
//        cartItems={cart?.items}
//        coupon={couponCodes}
//        //    handleQuantityChange={handleQuantityChange}
//        //    handleRemove={handleRemove}
//       />
//       {/* <div ref={checkoutButtonRef} style={{ height: "1px" }}></div> */}
//      </Box>
//     )}
//    </Box>
//   </Flex>
//  );
// };

// export default ProductListingPage;
